var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{attrs:{"centered":"","color":_vm.accentColor,"background-color":"transparent"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Crear")]),_c('v-tab',[_vm._v("Duplicar")])],1),_c('v-tabs-items',{staticStyle:{"background-color":"transparent"},attrs:{"dark":_vm.isDark},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card-text',{staticStyle:{"padding":"0px"}},[_c('MutationForm',{attrs:{"no-reset":"","mutation":"createBlock","constants":{ environmentId: _vm.environmentId },"submitButtonText":"Crear","submitButtonIcon":"add","submit-button-color":_vm.accentColor,"schema":{
            environmentId: {
              __graphQLType: 'ID'
            },
            name: {
              label: 'Nombre para identificador el bloque de contenido',
              type: 'string',
              description: 'Debe ser único y reconocible.',
              fieldOptions: {
                color: _vm.accentColor
              },
              newDesign: true,
              isDark: this.isDark
            },
            title: {
              label: 'Título del bloque de contenido',
              type: 'string',
              optional: true,
              description: 'Opcional. Se mostrará en las vistas.',
              fieldOptions: {
                color: _vm.accentColor
              },
              newDesign: true,
              isDark: this.isDark
            },
            type: {
              label: 'Tipo de bloque',
              type: 'string',
              fieldType: 'singleSelect',
              fieldOptions: {
                color: _vm.accentColor,
                options: [
                  { value: 'text', label: 'Texto Enriquecido' },
                  { value: 'html', label: 'Contenido HTML' }
                ]
              },
              newDesign: true,
              isDark: this.isDark
            }
          }},on:{"success":function($event){return _vm.$emit('create', $event)},"error":function($event){return _vm.$emit('error', $event)}}},[_c('v-btn',{attrs:{"slot":"left-buttons","dark":"","color":"#59a4f4"},on:{"click":function($event){return _vm.$emit('dismiss')}},slot:"left-buttons"},[_vm._v("Cancelar")])],1)],1)],1),_c('v-tab-item',[_c('v-card-text',{class:{ 'white--text': _vm.isDark }},[_c('div',{staticClass:"overline"},[_vm._v("Se duplicará:")]),_c('div',{staticClass:"caption"},[_vm._v(" - El bloque seleccionado, su contenido y todas sus opciones")]),_c('div',{staticClass:"overline"},[_vm._v("NO se duplicará:")]),_c('div',{staticClass:"caption"},[_vm._v(" - ID o nombre del bloque")]),_c('div',{staticClass:"caption"},[_vm._v(" - Cualquier componente (filtros, colecciones, etc) al que este bloque haga referencia.")]),_c('MutationForm',{attrs:{"no-reset":"","mutation":"duplicateBlock","environmentVariables":{ environmentId: _vm.environmentId },"submitButtonText":"Duplicar","submitButtonIcon":"content_copy","submit-button-color":_vm.accentColor,"schema":{
            blockId: {
              __graphQLType: 'ID',
              label: 'Bloque a copiar',
              fieldType: 'componentSelect',
              fieldOptions: {
                componentTypeName: 'blocks'
              },
              newDesign: true
            },
            newName: {
              label: 'Nombre para el nuevo bloque',
              type: 'string',
              description: 'Debe ser único y reconocible.',
              fieldOptions: {
                color: _vm.accentColor
              },
              newDesign: true,
              isDark: this.isDark
            }
          }},on:{"success":function($event){return _vm.$emit('create', $event)},"error":function($event){return _vm.$emit('error', $event)}}},[_c('v-btn',{attrs:{"slot":"left-buttons","dark":"","color":"#59a4f4"},on:{"click":function($event){return _vm.$emit('dismiss')}},slot:"left-buttons"},[_vm._v("Cancelar")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }